import { Image } from "artisn-ui-react";
import React, { useMemo } from "react";
import { useRouter } from "next/router";

import Styles from "./AnefiItemProduct.styles";
import { AnefiItemProductProps as Props } from "./AnefiItemProduct.types";

const AnefiItemProduct: React.FC<Props> = props => {
  const { className, product } = props;
  const { name, productId, attributes, images } = product ?? {};
  const { externalId } = attributes ?? {};
  const { additionalInfo } = product ?? {};
  const logoImage = useMemo(
    () => images?.find(image => image.name === "product_logo"),
    [images]
  );
  const { attributes: attributesAI } = additionalInfo ?? {};
  const rateRisk = useMemo(
    () => attributesAI?.find(attributeAI => attributeAI.key === "rateRisk"),
    [attributesAI]
  );
  const performance = useMemo(
    () => attributesAI?.find(attribute => attribute.key === "performance"),
    [attributesAI]
  );
  const { value } = performance ?? {};
  const { push } = useRouter();

  return (
    <Styles
      className={`AnefiItemProduct ${className}`}
      key={productId}
      onClick={() => {
        push(`/products/${productId}/${name}`);
      }}
    >
      <div className="AnefiItemProduct__image">
        {logoImage ? (
          <Image
            image={logoImage}
            alt={logoImage?.name}
            config={{ width: 50, height: 50 }}
          />
        ) : null}
      </div>
      <div className="AnefiItemProduct__container">
        <div className="AnefiItemProduct__top">
          <p className="AnefiItemProduct__title">{name}</p>
          <p className="AnefiItemProduct__subtitle">{externalId}</p>
        </div>
        <div className="AnefiItemProduct__bottom">
          <p className="AnefiItemProduct__rank">{rateRisk?.value}</p>
          <p className="AnefiItemProduct__percentage">{`+ ${value}`}</p>
        </div>
      </div>
    </Styles>
  );
};

AnefiItemProduct.defaultProps = {
  className: ""
};

export default AnefiItemProduct;
