import styled from "styled-components";

import { CONSTANTS } from "../../../config/constants";
import { FooterStyledProps as Props } from "./Footer.types";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const FooterStyled = styled.div<Props>`
  padding: 1.8rem 4.5rem;
  padding-bottom: 3rem;
  background-color: var(--palette-black-s0-l15);

  @media (min-width: ${tablet}px) {
    padding: 4rem 7.2rem;
    padding-bottom: 4.8rem;
  }

  .Footer {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: ${tablet}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__logo {
      padding-bottom: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (min-width: ${tablet}px) {
        padding-bottom: 0;
      }

      &__svg {
        path {
          fill: var(--palette-white);
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (min-width: ${tablet}px) {
        gap: 4rem;
      }

      &__item {
        color: var(--palette-white);
        text-decoration: none;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.8rem;
      }
    }
  }
`;

export default FooterStyled;
