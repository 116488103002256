import styled from "styled-components";

import { CONSTANTS } from "../../../config/constants";
import { TabsMenuStyledProps as Props } from "./TabsMenu.types";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const TabsMenuStyled = styled.div<Props>`
  .TabsMenu {
    &__tabs {
      gap: 3.4rem;
      margin-bottom: 2rem;

      @media (max-width: ${tablet}px) {
        gap: 1.8rem;
      }
    }

    &__transaction {
      display: none;

      @media (max-width: ${tablet}px) {
        display: unset;
      }

      .SelectTransactionDropdown__button {
        border-radius: 4.8rem;
        padding: 1.4rem;
      }

      p {
        display: none;
      }

      .SelectTransactionDropdown__button__arrow {
        display: none;
      }
    }
  }
`;

export default TabsMenuStyled;
