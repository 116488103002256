import Link from "next/link";
import React from "react";
import { useRouter } from "next/router";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";

import AnefiLogoSVG from "../../../../public/assets/images/logo.svg";

const Footer: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__content">
        <div className="Footer__logo" onClick={() => push("/")}>
          <AnefiLogoSVG className="Footer__logo__svg" />
        </div>
        <nav className="Footer__links">
          <Link href="/profile/support/contact-us">
            <a className="Footer__links__item">Contactos</a>
          </Link>
          <Link href="/profile/legal">
            <a className="Footer__links__item">Legales</a>
          </Link>
          <Link href="/profile/support">
            <a className="Footer__links__item">Ayuda</a>
          </Link>
        </nav>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
