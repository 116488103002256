import styled from "styled-components";

import { AnefiItemProductStyledProps as Props } from "./AnefiItemProduct.types";
import { AnefiItemProductPlaceholderProps as PlaceholderProps } from "./AnefiItemProduct.types";

import { CONSTANTS } from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const AnefiItemProductStyled = styled.div<Props>`
  display: flex;
  border: 0.1rem solid var(--palette-gray-s0-l90);
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1.8rem;
  gap: 1.6rem;
  cursor: pointer;

  .AnefiItemProduct {
    &__image {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &__container {
      width: 100%;
    }

    &__top {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l90);
      min-width: max-content;
    }

    &__title {
      color: var(--palette-black);
      font-size: 1.6rem;
      font-weight: 600;

      @media (max-width: ${mobile}px) {
        max-width: 14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__subtitle {
      color: var(--palette-gray-s0-l50);
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.2rem;
    }

    &__rank {
      color: var(--palette-black);
      font-size: 1.2rem;
      padding: 0.4rem 0.52rem;
      background-color: var(--palette-gray-s0-l90);
      border-radius: 2.4rem;
    }

    &__percentage {
      color: var(--palette-secondary-s40-l45);
      font-size: 1.6rem;
      font-weight: 600;

      @media (max-width: ${mobile}px) {
        font-size: 1.2rem;
      }
    }
  }
`;

export const AnefiItemProductPlaceholderStyled = styled.div<PlaceholderProps>`
  height: 12rem;
  border-radius: 1rem;
  margin-top: 1rem;

  .AnefiItemProductPlaceholder {
  }
`;

export default AnefiItemProductStyled;
