import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

import SelectTransactionDropdown from "../SelectTransactionDropdown/SelectTransactionDropdown";
import BalanceTabsMenuItem from "../navigation/BalanceTabsMenuItem/BalanceTabsMenuItem";
import HomeTabsMenuItem from "../navigation/HomeTabsMenuItem/HomeTabsMenuItem";
import InvestTabsMenuItem from "../navigation/InvestTabsMenuItem/InvestTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import Styles from "./TabsMenu.styles";
import { TabsMenuProps as Props } from "./TabsMenu.types";
import { CONSTANTS } from "config/constants";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";

const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { tablet } = CONSTANTS.BREAKPOINTS;

const TabsMenu: React.FC<Props> = props => {
  const { children } = props;
  const { pathname } = useRouter();
  const [active, setActive] = useState(-1);
  const { talkShopProvider } = useTalkShop();

  useEffect(() => {
    if (pathname === "/") {
      setActive(0);
    } else if (pathname.startsWith("/products")) {
      setActive(1);
    } else if (pathname.startsWith("/balance")) {
      setActive(3);
    } else if (pathname.startsWith("/profile")) {
      setActive(4);
    }
  }, [pathname]);

  const tabs = [
    <HomeTabsMenuItem
      key={0}
      className="TabsMenu__tab"
      active={active === 0}
    />,
    <InvestTabsMenuItem
      key={1}
      className="TabsMenu__tab"
      active={active === 1}
    />,
    <SelectTransactionDropdown key={2} className="TabsMenu__transaction" />,
    <BalanceTabsMenuItem
      key={3}
      className="TabsMenu__tab"
      active={active === 3}
    />,
    <UserTabsMenuItem key={4} className="TabsMenu__tab" active={active === 4} />
  ];

  if (talkShopProvider && WITH_TALK_SHOP) {
    return <Styles className="TabsMenu">{children}</Styles>;
  }

  return (
    <Styles className="TabsMenu">
      <TabsMenuUI tabs={tabs} maxMobileWidth={tablet + 1}>
        {children}
      </TabsMenuUI>
    </Styles>
  );
};

TabsMenu.defaultProps = {};

export default TabsMenu;
