import React from "react";

import { AnefiItemProductPlaceholderStyled as Styles } from "./AnefiItemProduct.styles";
import { AnefiItemProductPlaceholderProps as Props } from "./AnefiItemProduct.types";

const AnefiItemProductPlaceholder: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles
      className={`AnefiItemProductPlaceholder ${className} loading-shine`}
    />
  );
};

AnefiItemProductPlaceholder.defaultProps = {
  className: ""
};

export default AnefiItemProductPlaceholder;
