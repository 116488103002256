import { Portfolio, ProductDetailsWithAdditionalInfo } from "@anefi/types";
import router from "next/router";
import React, { useMemo } from "react";

import Styles from "./AnefiCard.styles";
import { AnefiCardProps as Props } from "./AnefiCard.types";
import Button from "components/global/Button/Button";
import AnefiItemProduct from "components/home/AnefiItemProduct/AnefiItemProduct";
import AnefiItemProductPlaceholder from "../AnefiItemProduct/AnefiItemProduct.placeholder";
import AnefiItemBriefcase from "./AnefiItemBriefcase/AnefiItemBriefcase";
import ErrorState from "components/error/ErrorState/ErrorState";

const AnefiCard: React.FC<Props> = props => {
  const { className, description, image, title, link, founds } = props;
  const { itemsProduct, status, itemsBriefcase, statusBriefcase } = props;
  const { refetchBriefcase, refetchProducts } = props;
  const { push } = router;
  const category = useMemo(
    () => itemsProduct?.find(item => item.name === "FONDOS"),
    [itemsProduct]
  );

  const renderItem = () => {
    if (status === "loading" || status === "idle") {
      return Array.from(new Array(3)).map((_, index) => (
        <AnefiItemProductPlaceholder key={index} />
      ));
    }
    if (status === "error") {
      return (
        <ErrorState
          title="¡Oops! hubo un problema al cargar los productos"
          subtitle="Al parecer algo falló al cargar los productos, intenta recargar la pantalla."
          buttonText="Intentar nuevamente"
          showButton
          onButtonClick={refetchProducts}
          className="AnefiCard__error"
        />
      );
    }
    if (status === "success") {
      return category?.products.map(product => (
        <AnefiItemProduct
          key={product.productId}
          product={product as ProductDetailsWithAdditionalInfo}
        />
      ));
    }
  };

  const renderItemBriefcase = () => {
    if (statusBriefcase === "loading" || status === "idle") {
      return Array.from(new Array(3)).map((_, index) => (
        <AnefiItemProductPlaceholder key={index} />
      ));
    }
    if (statusBriefcase === "error") {
      return (
        <ErrorState
          title="¡Oops! hubo un problema al cargar los portafolios"
          subtitle="Al parecer algo falló al cargar los portafolios, intenta recargar la pantalla."
          buttonText="Intentar nuevamente"
          showButton
          onButtonClick={refetchBriefcase}
          className="AnefiCard__error"
        />
      );
    }
    if (statusBriefcase === "success") {
      if (!itemsBriefcase) return null;
      return itemsBriefcase?.map(briefcase => (
        <AnefiItemBriefcase
          key={briefcase.id}
          briefcase={briefcase as Portfolio}
        />
      ));
    }
    return null;
  };

  return (
    <Styles className={`AnefiCard ${className}`}>
      <div className="AnefiCard__top">
        <div className="AnefiCard__top__container">
          {image ? <div className="AnefiCard__image">{image}</div> : null}
          <div className="AnefiCard__text">
            {title ? <h3 className="AnefiCard__text__title">{title}</h3> : null}
            {description ? (
              <p className="AnefiCard__text__description">{description}</p>
            ) : null}
          </div>
        </div>
        {link ? (
          <Button
            type="LINK"
            onClick={() => {
              push(`/${link}`);
            }}
          >
            Ver todos
          </Button>
        ) : null}
      </div>

      {founds ? renderItem() : renderItemBriefcase()}
    </Styles>
  );
};

AnefiCard.defaultProps = {
  className: "string"
};

export default AnefiCard;
