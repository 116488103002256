import { useContext } from "react";

import { TalkShopContext } from "./talkShop.context";
import { TalkShopProviderValue } from "./talkShop.context.types";

const useTalkShop = () => {
  const context = useContext<TalkShopProviderValue>(TalkShopContext);

  if (typeof context === "undefined") {
    throw new Error("useTalkShop must be used within a TalkShopProvider");
  }
  return context;
};

export default useTalkShop;
