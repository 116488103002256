import { Image } from "artisn-ui-react";
import React, { useMemo } from "react";
import router from "next/router";
import { events } from "artisn/analytics";
import { ProductDetailsWithAdditionalInfo } from "@anefi/types";

import Styles from "./CarouselItem.styles";
import { CarouselItemProps as Props } from "./CarouselItem.types";

const { logSelectProduct } = events.product;

const CarouselItem: React.FC<Props> = props => {
  const { className, product } = props;
  const { name, attributes, images, productId } = product ?? {};
  const { additionalInfo } = product ?? {};
  const { externalId } = attributes ?? {};
  const cdnImage = useMemo(
    () => images?.find(image => image.name === "product"),
    [images]
  );
  const logoImage = useMemo(
    () => images?.find(image => image.name === "product_logo"),
    [images]
  );
  const { attributes: attributesAI } = additionalInfo ?? {};
  const rateRisk = useMemo(
    () => attributesAI?.find(attributeAI => attributeAI.key === "rateRisk"),
    [attributesAI]
  );
  const { push } = router;

  const navigateToProductDetails = (
    product: ProductDetailsWithAdditionalInfo
  ) => {
    logSelectProduct({
      product
    });
    push(`/products/${productId}/${name}`);
  };

  return (
    <Styles className={`CarouselItem ${className}`}>
      <div
        className="CarouselItem__image"
        onClick={() => {
          navigateToProductDetails(product as ProductDetailsWithAdditionalInfo);
        }}
      >
        {cdnImage ? (
          <Image
            image={cdnImage}
            alt={cdnImage?.name}
            config={{ width: 245, height: 137 }}
          />
        ) : null}
        {logoImage ? (
          <Image
            image={logoImage}
            alt={logoImage?.name}
            config={{ width: 30, height: 30 }}
            className="CarouselItem__logo"
          />
        ) : null}
      </div>
      <div className="CarouselItem__description">
        <p className="CarouselItem__title">{name}</p>
        <p className="CarouselItem__rank">{rateRisk?.value}</p>
      </div>
      <p className="CarouselItem__subtitle">{externalId}</p>
    </Styles>
  );
};

CarouselItem.defaultProps = {
  className: ""
};

export default CarouselItem;
