import React from "react";

import { RecommendationsSliderPlaceholderStyled as Styles } from "./RecommendationsSlider.styles";
import { RecommendationsSliderPlaceholderProps as Props } from "./RecommendationsSlider.types";

const RecommendationsSliderPlaceholder: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles className={`RecommendationsSliderPlaceholder ${className}`}>
      <div className="RecommendationsSliderPlaceholder__image loading-shine" />
      <div className="RecommendationsSliderPlaceholder__container">
        <div className="RecommendationsSliderPlaceholder__title loading-shine" />
        <div className="RecommendationsSliderPlaceholder__rank loading-shine" />
      </div>
      <div className="RecommendationsSliderPlaceholder__subtitle loading-shine" />
      <div className="RecommendationsSliderPlaceholder__percentage loading-shine" />
    </Styles>
  );
};

RecommendationsSliderPlaceholder.defaultProps = {
  className: ""
};

export default RecommendationsSliderPlaceholder;
