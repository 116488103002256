import styled from "styled-components";

import { AnefiCardStyledProps as Props } from "./AnefiCard.types";

const AnefiCardStyled = styled.div<Props>`
  .AnefiCard {
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      padding-bottom: 2.2rem;

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.6rem;
      }
    }

    &__text {
      color: var(--palette-black);

      &__title {
        font-size: 2.2rem;
        font-weight: 600;
      }

      &__description {
        font-size: 1.6rem;
        padding-top: 0.4rem;
      }
    }
  }
`;

export default AnefiCardStyled;
