import styled from "styled-components";

import { CarouselItemStyledProps as Props } from "./CarouselItem.types";

const CarouselItemStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .CarouselItem {
    &__image {
      position: relative;
    }

    &__logo {
      position: absolute;
      top: 1.6rem;
      left: 1.6rem;
    }

    &__description {
      display: flex;
      gap: 1rem;
    }

    &__title {
      color: var(--palette-black);
      font-size: 1.6rem;
      font-weight: 600;
      max-width: 20rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__rank {
      color: var(--palette-black);
      background-color: var(--palette-gray-s0-l95);
      border-radius: 2.4rem;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.16rem 0.52rem;
    }

    &__subtitle {
      color: var(--palette-gray-s0-l50);
      font-size: 1.2rem;
      margin-top: 0.4rem;
    }
  }
`;

export default CarouselItemStyled;
