import React from "react";
import { Image } from "artisn-ui-react";
import { useRouter } from "next/router";

import Styles from "./AnefiItemBriefcase.styles";
import { AnefiItemBriefcaseProps as Props } from "./AnefiItemBriefcase.types";

import WarningSVG from "../../../../../public/assets/images/anefi/warning-icon.svg";

const AnefiItemBriefcase: React.FC<Props> = props => {
  const { className, briefcase } = props;
  const { name, riskLevel, performancePercent } = briefcase ?? {};
  const { products, id } = briefcase ?? {};
  const images = products?.slice(0, 3);
  const { push } = useRouter();

  return (
    <Styles
      className={`AnefiItemBriefcase ${className}`}
      onClick={() => {
        push(`/portfolio/${id}/${name}`);
      }}
    >
      <div className="AnefiItemBriefcase__top">
        <div className="AnefiItemBriefcase__top__title">{name}</div>
        <div className="AnefiItemBriefcase__top__rank">
          {riskLevel} <WarningSVG />
        </div>
      </div>
      <div className="AnefiItemBriefcase__bottom">
        <div className="AnefiItemBriefcase__bottom__images">
          {images?.map((image, index) => {
            const { urlImage, name } = image ?? {};

            return (
              <Image
                key={index}
                image={urlImage!}
                alt={name}
                config={{ width: 30, height: 30 }}
                className="AnefiItemBriefcase__bottom__image"
              />
            );
          })}
          {products!.length >= 3 ? (
            <p className="AnefiItemBriefcase__bottom__image4">
              +{+products!.length - 3}
            </p>
          ) : null}
        </div>
        <p className="AnefiItemBriefcase__bottom__percentage">
          + {performancePercent}%
        </p>
      </div>
    </Styles>
  );
};

AnefiItemBriefcase.defaultProps = {
  className: ""
};

export default AnefiItemBriefcase;
