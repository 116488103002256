import React from "react";

import Button from "components/global/Button/Button";
import Styles from "./ErrorState.styles";
import { ErrorStateProps as Props } from "./ErrorState.types";

import ErrorSVG from "../../../../public/assets/images/anefi/error.svg";

const ErrorState: React.FC<Props> = props => {
  const { icon, title, subtitle, showButton, className } = props;
  const { buttonText = "Reintentar", onButtonClick = () => {} } = props;

  return (
    <Styles className={`ErrorState ${className}`}>
      {icon ? icon : <ErrorSVG />}
      {title ? <p className="ErrorState__title">{title}</p> : null}
      {subtitle ? <p className="ErrorState__subtitle">{subtitle}</p> : null}
      {showButton ? (
        <Button onClick={() => onButtonClick()}>{buttonText}</Button>
      ) : null}
    </Styles>
  );
};

ErrorState.defaultProps = {};

export default ErrorState;
