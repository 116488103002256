import styled from "styled-components";

import { RecommendationsSliderStyledProps as Props } from "./RecommendationsSlider.types";
import { RecommendationsSliderPlaceholderProps as PlaceholderProps } from "./RecommendationsSlider.types";

const RecommendationsSliderStyled = styled.div<Props>`
  .RecommendationsSlider {
    &__slider {
      height: auto;
    }
  }
`;

export const RecommendationsSliderPlaceholderStyled = styled.div<PlaceholderProps>`
  .RecommendationsSliderPlaceholder {
    &__image {
      width: 24.4rem;
      height: 13.6rem;
      border-radius: 1.4rem;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      padding-top: 1.24rem;

      > div {
        border-radius: 0.6rem;
      }
    }

    &__title {
      width: 16rem;
      height: 2rem;
    }

    &__rank {
      width: 3.8rem;
      height: 2rem;
    }

    &__subtitle {
      width: 10rem;
      height: 2rem;
      margin-top: 0.4rem;
      border-radius: 0.6rem;
    }

    &__percentage {
      width: 6rem;
      height: 2.4rem;
      margin-top: 0.4rem;
      border-radius: 0.6rem;
    }
  }
`;

export default RecommendationsSliderStyled;
