import styled from "styled-components";

import { ErrorStateStyledProps as Props } from "./ErrorState.types";

const ErrorStateStyled = styled.div<Props>`
  display: grid;
  place-items: center;
  max-width: 40rem;
  margin: 0 auto;

  .ErrorState {
    &__title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: var(--palette-blue-s40-l25);
      font-weight: 600;
      text-align: center;
      padding-top: 1.6rem;
      padding-bottom: 1.2rem;
    }

    &__subtitle {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-gray-s0-l50);
      text-align: center;
      padding-bottom: 4rem;
    }
  }
`;

export default ErrorStateStyled;
