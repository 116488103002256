import { ProductDetailsWithAdditionalInfo } from "@anefi/types";
import { Slider } from "artisn-ui-react";
import React, { useMemo } from "react";

import ErrorState from "components/error/ErrorState/ErrorState";
import CarouselItem from "../CarouselItem/CarouselItem";
import RecommendationsSliderPlaceholder from "./RecommendationsSlider.placeholder";
import Styles from "./RecommendationsSlider.styles";
import { RecommendationsSliderProps as Props } from "./RecommendationsSlider.types";

const RecommendationsSlider: React.FC<Props> = props => {
  const { className } = props;
  const { items, status, refetch } = props;
  const category = useMemo(
    () => items?.find(item => item.name === "FONDOS"),
    [items]
  );

  const renderItem = () => {
    if (status === "loading" || status === "idle") {
      return (
        <Slider
          height={items ? 200 : 220}
          slideWidth={245}
          gap={12}
          className="RecommendationsSlider__slider"
        >
          {Array.from(new Array(6)).map((_, index) => (
            <RecommendationsSliderPlaceholder key={index} />
          ))}
        </Slider>
      );
    }
    if (status === "error") {
      return (
        <ErrorState
          title="¡Oops! Hubo un problema al cargar las recomendaciones"
          subtitle="Al parecer algo falló al cargar las recomendaciones, intenta recargando la página."
          buttonText="Intentar nuevamente"
          showButton
          onButtonClick={refetch}
          className="Profile__error"
        />
      );
    }
    if (status === "success") {
      return (
        <Slider
          height={items ? 200 : 220}
          slideWidth={245}
          gap={12}
          className="RecommendationsSlider__slider"
        >
          {category?.products.map(product => (
            <CarouselItem
              key={product.productId}
              product={product as ProductDetailsWithAdditionalInfo}
            />
          ))}
        </Slider>
      );
    }
    return null;
  };

  return (
    <Styles className={`RecommendationsSlider ${className}`}>
      {renderItem()}
    </Styles>
  );
};

RecommendationsSlider.defaultProps = {
  className: ""
};

export default RecommendationsSlider;
