import styled from "styled-components";

import { AnefiItemBriefcaseStyledProps as Props } from "./AnefiItemBriefcase.types";

const AnefiItemBriefcaseStyled = styled.div<Props>`
  border: 0.1rem solid var(--palette-gray-s0-l90);
  border-radius: 1rem;
  margin-top: 1rem;
  padding: 1.8rem;
  gap: 1.6rem;
  cursor: pointer;

  .AnefiItemBriefcase {
    &__top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.8rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l90);

      &__title {
        color: var(--palette-black);
        font-size: 1.8rem;
        font-weight: 600;
      }

      &__rank {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        padding: 0.4rem 0.52rem;
        background-color: var(--palette-gray-s0-l90);
        border-radius: 2.4rem;
        color: var(--palette-primary);
        font-size: 1rem;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.2rem;

      &__percentage {
        color: var(--palette-secondary-s40-l45);
        font-size: 1.8rem;
        font-weight: 600;
      }

      &__images {
        display: flex;
      }

      &__image {
        &:not(:first-child) {
          margin-left: -1.6rem;
        }
      }

      &__image4 {
        background-color: var(--palette-grey-blue-s15-l80);
        margin-left: -1.4rem;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--palette-white);
        font-weight: 600;
        font-size: 1.4rem;
        padding: 0.48rem;
        border-radius: 10rem;
        border: 0.1rem solid var(--palette-gray-s0-l90);
      }
    }
  }
`;

export default AnefiItemBriefcaseStyled;
